// Composables used for various compare metrics related content.

// <!-- API -->
import { ResourceRisks } from '@/models/v1/metrics';
import { computedEager } from '@vueuse/core';
import { watch } from 'vue';

// <!-- COMPOSABLES -->
import { useCompareMetricsGrid, useCompareMetricsQuery } from './grid';

// <!-- TYPES -->

/** @typedef {import('./grid/useCompareMetricsGrid').CompareMetricsRowData} CompareMetricsRowData */

/**
 * @typedef UseCompareMetricsOptions
 * @prop {import('vuex').Store<import('@/store/types/ECNBStore').ECNBState>} [store] Track an existing store reference, if it was already provisioned.
 * @prop {import('@tanstack/vue-query').QueryClient} [queryClient] Track an existing store reference, if it was already provisioned.
 * @prop {CompareMetricsRowData[] | V.Ref<CompareMetricsRowData[]>} [data] Possibly reactive initial data.
 * @prop {import('@/hooks/grid/useAgGridPagination').UseAgGridPaginationOptions} [pagination]
 * @prop {string | V.Ref<string>} [domLayout] Possibly reactive value controlling DOM layout of the grid.
 */

/**
 * @typedef UseCompareMetricsReturn
 * @prop {import('./grid/useCompareMetricsQuery').UseCompareMetricsQueryReturn} query
 * @prop {import('./grid/useCompareMetricsGrid').UseCompareMetricsGridReturn} grid
 */

/**
 * Define the composable.
 * @param {UseCompareMetricsOptions} [props]
 * @return {UseCompareMetricsReturn}
 */
export const useCompareMetrics = (props = {}) => {
    // DEFINE composables.
    const componentQueries = useCompareMetricsQuery(props);
    const componentGrid = useCompareMetricsGrid(props);

    // DESTRUCTURE required properties.
    const { data, isSelectionEmpty } = componentQueries;
    const { setRowData } = componentGrid;

    // STATE

    // DEFINE computed risks computation.
    const dataWithComputedRisks = computedEager(() => {
        return data.value.map((item) => {
            return {
                ...item,
                ...ResourceRisks.withComputedRisks(item),
            };
        });
    });

    // WATCHERS

    watch(
        dataWithComputedRisks,
        (current, previous) => {
            console.log(`sn::debug::update`, { current, previous });
            if (!!isSelectionEmpty.value) {
                // If no selection is present, assign nothing.
                setRowData([]);
            } else {
                // If selection is present, use current response data.
                setRowData(current);
            }
        },
        {
            deep: false,
            immediate: true,
        }
    );

    // EXPOSE

    return {
        grid: componentGrid,
        query: componentQueries,
    };
};
