// <!-- API -->
import { ref, computed, watch } from 'vue';
import { computedEager } from '@vueuse/core';
import { fetchLocations } from '@/api/v1/accounts/locations';
import { fetchWeatherStations } from '@/api/v1/accounts/weather';
import { fetchLocationRisks, fetchWeatherStationRisks } from '@/api/v1/legacy';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useQueries, useQuery, useQueryClient } from '@tanstack/vue-query';

// <!-- UTILITIES -->
import is from '@sindresorhus/is';
import { endOfDay, isAfter, isBefore, startOfDay, subYears } from 'date-fns';
import { createEventHook, resolveUnref } from '@vueuse/core';

// <!-- MODELS -->
import { ECNBState } from '@/store/types/ECNBStore';
import { LocationResource } from '@/models/v1/locations/Location';
import { DateTimeISO } from '@/utils/datetime';
import { DateRange } from '@/utils/filters';
import { Node, NodeSelector, NodeState } from '@/utils/tree';
import { ResourceRisks } from '@/models/v1/metrics';
import { ResourceRisksData } from '@/models/v1/metrics/ResourceRisks';

// <!-- TYPES -->
/** @typedef {import('vuex').Store<ECNBState>} Store */
/** @typedef {import('@/models/v1/weather/WeatherStation').WeatherStationResource} WeatherStationResource */

/**  @typedef {{ type: 'Location', id: number } & ResourceRisks} LocationRisks */
/**  @typedef {{ type: 'Weather Station', id: string } & ResourceRisks} WeatherStationRisks */

/**  @typedef {{ type: 'Location', id: number, label: string, missing: boolean } & LocationResource & ResourceRisksData} LocationRisksData */
/**  @typedef {{ type: 'Weather Station', id: string, label: string, missing: boolean } & WeatherStationResource & ResourceRisksData} WeatherStationRisksData */

/**
 * @typedef UseCompareMetricsQueryOptions
 * @prop {import('vuex').Store<import('@/store/types/ECNBStore').ECNBState>} [store] Track an existing store reference, if it was already provisioned.
 * @prop {import('@tanstack/vue-query').QueryClient} [queryClient] Track an existing store reference, if it was already provisioned.
 */

/**
 * @typedef UseCompareMetricsQueryReturn
 * @prop {import('@tanstack/vue-query').QueryClient} queryClient Query client in use.
 * @prop {Readonly<V.Ref<(LocationRisksData | WeatherStationRisksData)[]>> | V.ComputedRef<(LocationRisksData | WeatherStationRisksData)[]>} data Computed reference collection of data.
 * @prop {Readonly<V.Ref<boolean>> | V.ComputedRef<boolean>} isLoading Is the resource data loading?
 * @prop {Readonly<V.Ref<boolean>> | V.ComputedRef<boolean>} isReady Is the resource data finished loading?
 * @prop {Readonly<V.Ref<boolean>> | V.ComputedRef<boolean>} isSelectionEmpty Is the user selection empty?
 * @prop {Readonly<V.Ref<boolean>> | V.ComputedRef<boolean>} isResponseEmpty Is the resource response empty?
 * @prop {import('@vueuse/core').EventHookOn<{ data: LocationRisks | WeatherStationRisks }>} onResponse Callback that can process responses directly.
 * @prop {import('@vueuse/core').EventHookOn<{ error?: unknown }>} onError Callback that can process errors.
 */

/**
 * Define the composable.
 * @param {UseCompareMetricsQueryOptions} props
 * @return {UseCompareMetricsQueryReturn}
 */
export const useCompareMetricsQuery = (props = {}) => {
    // EVENTS

    /** @type {import("@vueuse/core").EventHook<{ data: LocationRisks | WeatherStationRisks }>} */
    const queryResponse = createEventHook();
    const onResponse = queryResponse.on;
    const handleResponse = queryResponse.trigger;

    /** @type {import("@vueuse/core").EventHook<{ error?: unknown }>} */
    const queryError = createEventHook();
    const onError = queryError.on;
    const handleError = queryError.trigger;

    // LIFECYCLE

    onError((param) => {
        if (!!param.error) {
            console.error(param.error);
        }
    });

    onResponse((response) => {
        const { data } = response;
        console.log(`HIT sn::debug::risks`, data);
        switch (data.type) {
            case 'Location':
                risksByLocationID.value = Object.assign(
                    risksByLocationID.value,
                    {
                        [data.id]: data,
                    }
                );
                return;
            case 'Weather Station':
                risksByStationID.value = Object.assign(risksByStationID.value, {
                    [data.id]: data,
                });
                return;
            default:
                handleError({
                    error: new Error(
                        `Unknown response type: ${JSON.stringify(response)}`
                    ),
                });
                return;
        }
    });

    // SERVICES

    const store = props?.store ?? useStore();
    const queryClient = props?.queryClient ?? useQueryClient();

    // STATE

    // ==== ACCOUNT ====
    /** @type {V.Ref<globalThis.Account.Model>} */
    const account = ref(store?.state?.accounts?.account ?? null);
    const selectedAccountId = computed(() => account.value?.id ?? -1);
    const isAccountSelected = computed(
        () => !!store.state.accounts.account?.id && selectedAccountId.value > 0
    );

    // ==== DATE RANGE FILTER ====
    const dateRange = computed(() =>
        getCurrentDateRange(store.state.analysis.filters.dates)
    );
    const hasDateRange = computedEager(() => !!dateRange.value);
    const normalizedDates = computed(() => {
        const { minDate, maxDate } = getNormalizedDateRange(dateRange.value);
        return { start: minDate, end: maxDate };
    });

    // ==== LOCATIONS ====
    /** @type {V.Ref<LocationResource[]>} */
    const indexLocations = ref([]);

    /** @type {V.Ref<Record<number, ResourceRisks>>} */
    const risksByLocationID = ref({});

    const datedLocations = computed(() =>
        getLocationsWithinDateRange(indexLocations.value, dateRange.value)
    );
    const checkedLocationNodes = computed(() =>
        getCheckedLocationNodes(store.state.analysis.filters.locations.tree)
    );
    const checkedLocationIDs = computed(() =>
        getCheckedLocationIDs(checkedLocationNodes.value)
    );
    const filteredLocations = computed(() =>
        getFilteredLocations(indexLocations.value, checkedLocationIDs.value)
    );
    const hasFilteredLocations = computed(
        () => filteredLocations.value.length === 0
    );

    // ==== WEATHER STATIONS ====
    /** @type {V.Ref<WeatherStationResource[]>} */
    const indexStations = ref([]);

    /** @type {V.Ref<Record<string, ResourceRisks>>} */
    const risksByStationID = ref({});

    const datedStations = computed(() =>
        getWeatherStationsWithinDateRange(indexStations.value)
    );
    const checkedStationNodes = computed(() =>
        getCheckedWeatherStationNodes(
            store.state.analysis.filters.stations.tree
        )
    );
    const checkedStationIDs = computed(() =>
        getCheckedWeatherStationIDs(checkedStationNodes.value)
    );
    const filteredStations = computed(() =>
        getFilteredWeatherStations(indexStations.value, checkedStationIDs.value)
    );
    const hasFilteredStations = computed(
        () => filteredStations.value.length === 0
    );

    /** Create query for account locations. */
    const accountLocations = useQuery({
        queryKey: QueryKeys.locations(selectedAccountId),
        queryFn: ({ queryKey, signal }) => {
            // console.log(`sn::debug::locations`, queryKey);
            return fetchLocations({ id: queryKey[1] }, { signal });
        },
        enabled: isAccountSelected,
        onError: (e) => console.error(e),
        onSuccess: (data) => {
            indexLocations.value = data;
            // console.log(`HIT sn::debug::locations`, data);
        },
        staleTime: 1000 * 60 * 30,
        refetchOnWindowFocus: false,
    });

    /** Create query for account weather stations. */
    const accountStations = useQuery({
        queryKey: QueryKeys.weatherStations(selectedAccountId),
        queryFn: ({ queryKey, signal }) => {
            // console.log(`sn::debug::stations`, queryKey);
            return fetchWeatherStations({ id: queryKey[1] }, { signal });
        },
        enabled: isAccountSelected,
        onError: (e) => console.error(e),
        onSuccess: (data) => {
            indexStations.value = data;
            // console.log(`HIT sn::debug::stations`, data);
        },
        staleTime: 1000 * 60 * 30,
        refetchOnWindowFocus: false,
    });

    /** Define query options for each location. */
    const locationRisksOptions = computed(() =>
        filteredLocations.value.map((resource) => {
            /**
             * @type {import('@tanstack/vue-query').UseQueryOptions}
             */
            const options = {
                queryKey: QueryKeys.locationRisks(
                    selectedAccountId,
                    resource.id,
                    dateRange
                ),
                queryFn: async ({ queryKey, signal }) => {
                    if (
                        !isLocationResourceWithinDateRange(
                            resource,
                            dateRange.value
                        )
                    ) {
                        // Exit early with an empty set of data to allow this location
                        // to appear in the table without bothering the server.
                        return { id: resource?.id, data: {} };
                    }

                    const { minDate, maxDate } = getNormalizedDateRange(
                        dateRange.value
                    );
                    const id = resource?.id;
                    const min = Math.trunc(minDate / 1000);
                    const max = Math.trunc(maxDate / 1000);
                    const data = await fetchLocationRisks(
                        {
                            id: resource?.id,
                            name: resource?.name,
                            minDate: min,
                            maxDate: max,
                        },
                        { signal }
                    );
                    return { id, data };
                },
                onError: (e) => handleError(e),
                /** @param {{ id: number, data: Awaited<ReturnType<fetchLocationRisks>> }} response */
                onSuccess: (response) => {
                    // Trigger the event.
                    handleResponse({
                        data: {
                            id: response.id,
                            type: 'Location',
                            ...ResourceRisks.fromData({
                                start_date: response.data.r_start_date,
                                end_date: response.data.r_end_date,
                                temp_mean: response.data.r_temp_mean,
                                rh_mean: response.data.r_rh_mean,
                                dp_mean: response.data.r_dp_mean,
                                mrf: response.data.r_mrf,
                                last_twpi: response.data.r_last_twpi,
                                dc_min: response.data.r_dc_min,
                                dc_max: response.data.r_dc_max,
                                dc_span: response.data.r_dc_span,
                                emc_min: response.data.r_emc_min,
                                emc_max: response.data.r_emc_max,
                                emc_mean: response.data.r_emc_mean,
                            }),
                        },
                    });
                },
                staleTime: 0,
                retry: false,
                refetchOnWindowFocus: false,
            };
            return options;
        })
    );

    /** Create parallel location risks queries. */
    const locationRisks = useQueries({
        queries: locationRisksOptions,
    });

    /** Define query options for each weather station. */
    const stationRisksOptions = computed(() =>
        filteredStations.value.map((resource) => {
            /**
             * @type {import('@tanstack/vue-query').UseQueryOptions}
             */
            const options = {
                queryKey: QueryKeys.weatherStationRisks(
                    selectedAccountId,
                    resource.id,
                    dateRange
                ),
                queryFn: async ({ queryKey, signal }) => {
                    const { minDate, maxDate } = getNormalizedDateRange(
                        dateRange.value
                    );
                    const id = resource?.id;
                    const data = await fetchWeatherStationRisks(
                        {
                            id: Number(resource?.id),
                            name: resource?.name,
                            minDate: Math.trunc(minDate / 1000),
                            maxDate: Math.trunc(maxDate / 1000),
                        },
                        { signal }
                    );
                    return { id, data };
                },
                onError: (e) => handleError(e),
                /** @param {{ id: string, data: Awaited<ReturnType<fetchWeatherStationRisks>> }} response */
                onSuccess: (response) => {
                    // Trigger the event.
                    handleResponse({
                        data: {
                            id: response.id,
                            type: 'Weather Station',
                            ...ResourceRisks.fromData({
                                start_date: response.data.r_start_date,
                                end_date: response.data.r_end_date,
                                temp_mean: response.data.r_temp_mean,
                                rh_mean: response.data.r_rh_mean,
                                dp_mean: response.data.r_dp_mean,
                                mrf: response.data.r_mrf,
                                last_twpi: response.data.r_last_twpi,
                                dc_min: response.data.r_dc_min,
                                dc_max: response.data.r_dc_max,
                                dc_span: response.data.r_dc_span,
                                emc_min: response.data.r_emc_min,
                                emc_max: response.data.r_emc_max,
                                emc_mean: response.data.r_emc_mean,
                            }),
                        },
                    });
                },
                staleTime: 0,
                retry: false,
                refetchOnWindowFocus: false,
            };
            return options;
        })
    );

    /** Create parallel weather station risks queries. */
    const stationRisks = useQueries({
        queries: stationRisksOptions,
    });

    /** Is the component loading? */
    const isLoading = computed(() => {
        if (
            accountLocations.isLoading.value ||
            accountLocations.isFetching.value
        ) {
            return true;
        }
        if (
            accountStations.isLoading.value ||
            accountStations.isFetching.value
        ) {
            return true;
        }
        if (locationRisks.some((q) => !!q.isLoading || !!q.isFetching)) {
            return true;
        }
        if (stationRisks.some((q) => !!q.isLoading || !!q.isFetching)) {
            return true;
        }
        return false;
    });

    /** Is the component ready? */
    const isReady = computed(() => !isLoading.value);

    /**
     * Resolved resource risks data. Empty to start.
     * @type {Readonly<V.Ref<(LocationRisksData | WeatherStationRisksData)[]>> | V.ComputedRef<(LocationRisksData | WeatherStationRisksData)[]>} Computed reference collection of data.
     */
    const data = computedEager(() => {
        // LOCATIONS
        const _locations = {
            get filtered() {
                return filteredLocations.value;
            },
            get risks() {
                return risksByLocationID.value;
            },
            compute() {
                // COMPUTE location resource data.
                const { risks } = this;
                return this.filtered.map((location) => {
                    const mapped = Object.assign({}, location, {
                        ...ResourceRisks.toData(risks[location.id]),
                        id: location.id,
                        type: /** @type {const} */ ('Location'),
                        label: location.label,
                        missing: is.nullOrUndefined(risks[location.id]),
                    });
                    // Mapped object.
                    return mapped;
                });
            },
        };
        // WEATHER STATIONS
        const _weatherStations = {
            get filtered() {
                return filteredStations.value;
            },
            get risks() {
                return risksByStationID.value;
            },
            compute() {
                // COMPUTE weather station resource data.
                const { risks } = this;
                return this.filtered.map((station) => {
                    const mapped = Object.assign({}, station, {
                        ...ResourceRisks.toData(risks[station.id]),
                        id: String(station.id),
                        type: /** @type {const} */ ('Weather Station'),
                        label: station.name,
                        missing: is.nullOrUndefined(risks[station.id]),
                    });
                    // Mapped object.
                    return mapped;
                });
            },
        };
        // COMPUTE merged resource data.
        return [..._locations.compute(), ..._weatherStations.compute()];
    });

    /** Is the treeview selection empty? */
    const isSelectionEmpty = computedEager(
        () =>
            checkedLocationIDs.value.length === 0 &&
            checkedStationIDs.value.length === 0
    );

    /** Is the resolved data collection empty? */
    const isResponseEmpty = computedEager(
        () => !!data && data?.value?.length === 0
    );

    // WATCHERS

    watch(
        () => store.state.accounts.account,
        (current, previous) => {
            if (!previous || current.id !== previous.id) {
                account.value = current;
                indexLocations.value = [];
                indexStations.value = [];
                risksByLocationID.value = {};
                risksByStationID.value = {};
                // console.log(`sn::debug::watch-account?`, current);
            }
        },
        {
            deep: false,
            immediate: true,
        }
    );

    watch(
        () => store.state.analysis.filters.locations.tree.nodes,
        (current, previous) => {
            if (!previous || current.id !== previous.id) {
                // console.log(`sn::debug::watch-account?`, current);
                queryClient.refetchQueries({
                    queryKey: QueryKeys.locations(selectedAccountId),
                });
            }
        },
        {
            deep: true,
            immediate: true,
        }
    );

    watch(
        () => store.state.analysis.filters.stations.tree.nodes,
        (current, previous) => {
            if (!previous || current.id !== previous.id) {
                // console.log(`sn::debug::watch-account?`, current);
                queryClient.refetchQueries({
                    queryKey: QueryKeys.weatherStations(selectedAccountId),
                });
            }
        },
        {
            deep: true,
            immediate: true,
        }
    );

    return {
        data,
        queryClient,
        isLoading,
        isReady,
        isSelectionEmpty,
        isResponseEmpty,
        onResponse,
        onError,
    };
};

/**
 * Query keys used by this composable.
 */
const QueryKeys = Object.freeze({
    all: /** @type {const} */ (['accounts']),
    /** @param {import('@vueuse/core').MaybeRef<globalThis.Account.Model['id']>} account */
    locations: (account) =>
        /** @type {const} */ ([...QueryKeys.all, account, 'locations']),
    /** @param {import('@vueuse/core').MaybeRef<globalThis.Account.Model['id']>} account */
    weatherStations: (account) =>
        /** @type {const} */ ([...QueryKeys.all, account, 'stations']),
    /**
     * @param {import('@vueuse/core').MaybeRef<globalThis.Account.Model['id']>} account
     * @param {import('@vueuse/core').MaybeRef<import('@/models/v1/locations/Location').LocationResource['id']>} location
     * @param {import('@vueuse/core').MaybeRef<Interval>} dates
     */
    locationRisks: (account, location, dates) =>
        /** @type {const} */ ([
            ...QueryKeys.all,
            account,
            'locations',
            location,
            'risks',
            dates,
        ]),
    /**
     * @param {import('@vueuse/core').MaybeRef<globalThis.Account.Model['id']>} account
     * @param {import('@vueuse/core').MaybeRef<import('@/models/v1/weather/WeatherStation').WeatherStationResource['id']>} station
     * @param {import('@vueuse/core').MaybeRef<Interval>} dates
     */
    weatherStationRisks: (account, station, dates) =>
        /** @type {const} */ ([
            ...QueryKeys.all,
            account,
            'stations',
            station,
            'risks',
            dates,
        ]),
});

/**
 * Get the current date range.
 * @param {DateRangeFilter} filter
 * @returns {Interval}
 */
const getCurrentDateRange = (filter) => {
    // const { start, end } = store.state.analysis.filters.dates;
    const { start, end } = filter;
    // VALIDATE
    const filterStart = isDateInvalid(start?.valueOf())
        ? Date.UTC(1970, 0, 1, 0, 0, 0, 0)
        : startOfDay(start);
    const filterEnd = isDateInvalid(end?.valueOf())
        ? endOfDay(Date.now())
        : endOfDay(end);
    // EXPOSE
    return DateRange.clone({
        start: filterStart,
        end: filterEnd,
    });
};

/**
 * Check if location is within the current date range.
 * @param {import('@/models/v1/locations/Location').LocationResource} resource
 * @param {Interval | V.Ref<Interval>} dateRange
 */
const isLocationResourceWithinDateRange = (resource, dateRange) => {
    // Resolve interval being checked.
    const filterRange = resolveUnref(dateRange);

    // NORMALIZE
    const start = normalizeStartDate(filterRange.start);
    const end = normalizeEndDate(filterRange.end);
    const min = normalizeStartDate(parseDate(resource?.minDate));
    const max = normalizeEndDate(parseDate(resource?.maxDate));

    // FILTER
    const isMinDateAfterEndBound = isAfter(min, end);
    const isMaxDateBeforeStartBound = isBefore(max, start);

    if (is.nan(min) || is.nan(max)) {
        return true;
    }

    return (
        (is.nan(start) || !isMaxDateBeforeStartBound) &&
        (is.nan(end) || !isMinDateAfterEndBound)
    );
};

/**
 * Check if the weather station is within the current date range. Always returns `true`.
 * @param {WeatherStationResource} resource
 */
const isWeatherStationResourceWithinDateRange = (resource) => true;

/**
 * Get the checked location tree nodes.
 * @param {Treeview.Tree} filter
 */
const getCheckedLocationNodes = (filter) => {
    // const filter = store.state.analysis.filters.locations.tree;
    const nodes = Object.values(filter.nodes);
    return nodes
        .filter(Node.isLocationNode)
        .filter((n) => NodeState.isChecked(n.state));
};

/**
 * Get the checked weather station tree nodes.
 * @param {Treeview.Tree} filter
 */
const getCheckedWeatherStationNodes = (filter) => {
    // const filter = store.state.analysis.filters.stations.tree;
    const nodes = Object.values(filter.nodes);
    return nodes
        .filter(Node.isWeatherStationNode)
        .filter((n) => NodeState.isChecked(n.state));
};

/**
 * Get the location resource collection within a particular date range.
 * @param {LocationResource[] | V.Ref<LocationResource[]>} locations
 * @param {Interval | V.Ref<Interval>} dateRange
 */
const getLocationsWithinDateRange = (locations, dateRange) => {
    const data = resolveUnref(locations);
    const filtered = data.filter((r) =>
        isLocationResourceWithinDateRange(r, dateRange)
    );
    return filtered;
};

/**
 * Get the weather station resource collection within a particular date range.
 * @param {WeatherStationResource[] | V.Ref<WeatherStationResource[]>} stations
 */
const getWeatherStationsWithinDateRange = (stations) => {
    const data = resolveUnref(stations);
    const filtered = data.filter((r) =>
        isWeatherStationResourceWithinDateRange(r)
    );
    return filtered;
};

/**
 * Get the checked location ids.
 * @param {Treeview.Node[] | V.Ref<Treeview.Node[]>} checkedNodes
 */
const getCheckedLocationIDs = (checkedNodes) => {
    const nodes = resolveUnref(checkedNodes);
    return nodes
        .map((n) => n.id)
        .map(NodeSelector.readResourceID)
        .map(Number);
};

/**
 * Get the checked weather station ids.
 * @param {Treeview.Node[] | V.Ref<Treeview.Node[]>} checkedNodes
 */
const getCheckedWeatherStationIDs = (checkedNodes) => {
    const nodes = resolveUnref(checkedNodes);
    return nodes
        .map((n) => n.id)
        .map(NodeSelector.readResourceID)
        .map(String);
};

/**
 * Get the filtered locations.
 * @param {LocationResource[] | V.Ref<LocationResource[]>} locations
 * @param {number[] | V.Ref<number[]>} checkedIDs
 */
const getFilteredLocations = (locations, checkedIDs) => {
    const data = resolveUnref(locations);
    const selectedIDs = resolveUnref(checkedIDs);
    const filtered = data.filter((r) => {
        return selectedIDs.includes(r.id);
    });
    return filtered;
};

/**
 * Get the filtered weather stations.
 * @param {WeatherStationResource[] | V.Ref<WeatherStationResource[]>} stations
 * @param {string[] | V.Ref<string[]>} checkedIDs
 */
const getFilteredWeatherStations = (stations, checkedIDs) => {
    const data = resolveUnref(stations);
    const selectedIDs = resolveUnref(checkedIDs);
    const filtered = data.filter((r) => {
        return selectedIDs.includes(String(r.id));
    });

    return filtered;
};

/**
 * Calculate valid start and end dates.
 * @param {Interval} range
 */
const getNormalizedDateRange = (range) => {
    // VALIDATE
    const defaultStart = startOfDay(subYears(Date.now(), 1)).valueOf();
    const defaultEnd = endOfDay(Date.now()).valueOf();
    const _start = range?.start?.valueOf() ?? NaN;
    const _end = range?.end?.valueOf() ?? NaN;

    // CLAMP
    const SAFE_MAX_DATE = Date.UTC(3000, 0, 1, 0, 0, 0, 0);
    const SAFE_MIN_DATE = Date.UTC(1, 0, 1, 0, 0, 0, 0);
    const minDate =
        is.nan(_start) || isBefore(_start, SAFE_MIN_DATE)
            ? defaultStart
            : _start;
    const maxDate =
        is.nan(_end) || isAfter(_end, SAFE_MAX_DATE) ? defaultEnd : _end;
    return {
        minDate: Math.round(minDate.valueOf()),
        maxDate: Math.round(maxDate.valueOf()),
    };
};

/**
 * Test if input date is invalid.
 * @param {IDate} value
 * @returns {boolean}
 */
const isDateInvalid = (value) => is.nullOrUndefined(value) || is.nan(value);

/**
 * Test if input date string is invalid.
 * @param {string} value
 * @returns {boolean}
 */
const isDateStringEmpty = (value) =>
    is.nullOrUndefined(value) || is.emptyStringOrWhitespace(value);

/**
 * Normalize start date.
 * @param {IDate} value
 * @returns {IDate}
 */
const normalizeStartDate = (value) => {
    return isDateInvalid(value) ? NaN : startOfDay(value);
};

/**
 * Normalize end date.
 * @param {IDate} value
 * @returns {IDate}
 */
const normalizeEndDate = (value) => {
    return isDateInvalid(value) ? NaN : endOfDay(value);
};

/**
 * Parse resource date string.
 * @param {string} value
 * @returns {IDate}
 */
const parseDate = (value) =>
    isDateStringEmpty(value) ? NaN : DateTimeISO.parse(value);
