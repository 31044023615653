<template>
    <SidebarFilter
        :title="title"
        :border="border"
        v-if="['T', 'RH', 'TRH', 'DP', 'all'].includes(currentFilterType)"
    >
        <template #content>
            <div
                v-if="isNARAEnabled"
                class="flex justify-center"
            >
                <p class="text-sm text-gray-500 pt-0.5">NARA Standards</p>
                <TeleportTrigger
                    class="pl-.5"
                    @open="viewNARAStandardsModal.open"
                />
                <ViewNARAStandardsModal
                    :show="viewNARAStandardsModal.isOpen.value"
                    @close="viewNARAStandardsModal.close"
                />
            </div>
            <FormKit
                :id="`${title}-temp-form`.toLocaleLowerCase().trim()"
                type="form"
                :actions="false"
                @submit.prevent="$log('Update temp limit...')"
            >
                <TransitionGroup
                    name="list"
                    tag="div"
                    class="flex flex-col gap-y-2"
                >
                    <!-- TEMPERATURE FILTER -->
                    <div
                        :class="formClasses.section"
                        v-if="['T', 'TRH', 'all'].includes(currentFilterType)"
                    >
                        <FormKit
                            id="filter-limit-temp-lower"
                            type="number"
                            placeholder="Min"
                            :classes="formClasses.number"
                            v-model="T.lower"
                            @input="limits.onInput().T.lower"
                            :min="-500"
                            :max="500"
                            :step="1"
                            :delay="1000"
                            :preserve="true"
                        >
                            <template #prefix>
                                <div :class="formClasses.number.prefix">T:</div>
                            </template>
                        </FormKit>
                        <FormKit
                            id="filter-limit-temp-upper"
                            type="number"
                            placeholder="Max"
                            :classes="formClasses.number"
                            v-model="T.upper"
                            @input="limits.onInput().T.upper"
                            :min="-500"
                            :max="500"
                            :step="1"
                            :delay="1000"
                            :preserve="true"
                        >
                            <template #prefix>
                                <div :class="formClasses.number.dash">-</div>
                            </template>
                        </FormKit>
                        <FormKit
                            id="filter-limit-temp-show"
                            type="checkbox"
                            label="Show"
                            @input="limits.onInput().T.checked"
                            v-model="T.checked"
                            :classes="formClasses.checkbox"
                            :preserve="true"
                            v-if="showButtons"
                        />
                    </div>
                    <!-- RELATIVE HUMIDITY FILTER -->
                    <div
                        :class="formClasses.section"
                        v-if="['RH', 'TRH', 'all'].includes(currentFilterType)"
                    >
                        <FormKit
                            id="filter-limit-rh-lower"
                            type="number"
                            placeholder="Min"
                            :classes="formClasses.number"
                            @input="limits.onInput().RH.lower"
                            v-model="RH.lower"
                            :min="-500"
                            :max="500"
                            :step="1"
                            :delay="1000"
                            :preserve="true"
                        >
                            <template #prefix>
                                <div :class="formClasses.number.prefix">
                                    RH:
                                </div>
                            </template>
                        </FormKit>
                        <FormKit
                            id="filter-limit-rh-upper"
                            type="number"
                            placeholder="Max"
                            :classes="formClasses.number"
                            @input="limits.onInput().RH.upper"
                            v-model="RH.upper"
                            :min="-500"
                            :max="500"
                            :step="1"
                            :delay="1000"
                            :preserve="true"
                        >
                            <template #prefix>
                                <div :class="formClasses.number.dash">-</div>
                            </template>
                        </FormKit>
                        <FormKit
                            id="filter-limit-rh-show"
                            type="checkbox"
                            label="Show"
                            @input="limits.onInput().RH.checked"
                            v-model="RH.checked"
                            :classes="formClasses.checkbox"
                            :preserve="true"
                            v-if="showButtons"
                        />
                    </div>
                    <!-- DEW POINT FILTER -->
                    <div
                        :class="formClasses.section"
                        v-if="['DP', 'all'].includes(currentFilterType)"
                    >
                        <FormKit
                            id="filter-limit-dp-lower"
                            type="number"
                            placeholder="Min"
                            :classes="formClasses.number"
                            @input="limits.onInput().DP.lower"
                            v-model="DP.lower"
                            :min="-500"
                            :max="500"
                            :step="1"
                            :delay="1000"
                            :preserve="true"
                        >
                            <template #prefix>
                                <div :class="formClasses.number.prefix">
                                    DP:
                                </div>
                            </template>
                        </FormKit>
                        <FormKit
                            id="filter-limit-dp-upper"
                            type="number"
                            placeholder="Max"
                            :classes="formClasses.number"
                            @input="limits.onInput().DP.upper"
                            v-model="DP.upper"
                            :min="-500"
                            :max="500"
                            :step="1"
                            :delay="1000"
                            :preserve="true"
                        >
                            <template #prefix>
                                <div :class="formClasses.number.dash">-</div>
                            </template>
                        </FormKit>
                        <FormKit
                            id="filter-limit-dp-show"
                            type="checkbox"
                            label="Show"
                            @input="limits.onInput().DP.checked"
                            v-model="DP.checked"
                            :classes="formClasses.checkbox"
                            :preserve="true"
                            v-if="showButtons"
                        />
                    </div>
                </TransitionGroup>
            </FormKit>
            <DebugFrame
                v-if="debug"
                id="generic"
                :startHidden="frame.startHidden"
                :debug="frame.isEnabled"
                :data="frame.data"
            />
        </template>
    </SidebarFilter>
</template>

<script>
    // <!-- API -->
    import { computed, defineComponent, onBeforeUnmount } from 'vue';

    // <!-- COMPONENTS -->
    import SidebarFilter from '@/components/sidebar/SidebarFilter.vue';
    import DebugFrame from '@/components/debug/DebugFrame.vue';
    import TeleportTrigger from '@/components/modals/TeleportTrigger.vue';
    import ViewNARAStandardsModal from '~Analysis/components/modals/ViewNARAStandardsModal.vue';

    // <!-- COMPOSABLES -->
    import { useLimitFilterRecord } from '@/components/sidebar/hooks/useLimitFilterRecord';
    import { useModalToggle } from '@/hooks/modals';
    import { useNARAFeature } from '@/utils/features';

    // <!-- DEBUG -->
    import {
        useDebugFrame,
        DebugObject,
    } from '@/hooks/reactivity/useDebugFrame';

    // <!-- TYPES -->
    /** @typedef {import('@/features/analysis/hooks/useAnalysisChart').AnalysisChartConstants['GraphTypes'][number]} GraphType */
    /** @typedef {Extract<GraphType, 'T' | 'RH' | 'TRH' | 'DP'> | 'all' | ''} FilterType */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'LimitsFilter',
        components: {
            SidebarFilter,
            DebugFrame,
            TeleportTrigger,
            ViewNARAStandardsModal,
        },
        props: {
            /** Filter section title. */
            title: {
                /** @type {V.PropType<String>} */
                type: String,
                required: true,
            },
            /** If true, show the top border. */
            border: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: true,
            },
            /** If true, show the buttons. */
            showButtons: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            currentFilterType: {
                /** @type {V.PropType<'T' | 'TRH' | 'RH' | 'DP' | 'all'>} */
                type: /** @type {any} */ (String),
                default: 'T',
            },
            debug: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, context) {
            // ==== COMPOSE ====
            const { store, limits } = useLimitFilterRecord();

            const { isNARAEnabled } = useNARAFeature();

            /** Define the modal. */
            const { modal: viewNARAStandardsModal } = useModalToggle();

            // ==== STATE ====
            /** Input classes. */
            const formClasses = {
                section: 'grid grid-cols-5 pt-1',
                number: {
                    outer: '$reset outer block text-xs sm:text-sm col-span-2',
                    wrapper: '$reset wrapper',
                    inner: '$reset inner flex flex-row items-baseline whitespace-nowrap overflow-x-visible',
                    input: '$reset input appearance-none box-content block w-14 px-2 py-2 text-xs rounded-md shadow-sm border border-gray-300 placeholder-gray-400 focus:outline-none focus:border-primary-500 disabled:bg-gray-100 disabled:cursor-not-allowed',
                    prefix: 'prefix block flex-auto w-5 mr-2 text-right',
                    dash: 'prefix block grow w-2 mx-2 text-center',
                },
                checkbox: {
                    outer: '$reset outer block w-full text-xs sm:text-sm col-span-1 place-self-left',
                    wrapper:
                        '$reset wrapper w-full py-2 flex flex-row items-center disabled:cursor-not-allowed',
                    label: '$reset label block text-xs sm:text-sm',
                    inner: '$reset inner',
                    input: '$reset input h-3.5 w-3.5 appearance-none mb-0.5 mr-1',
                },
            };

            // ==== LIFECYCLE ====

            // Reset the limits data categories.
            limits.T.resetCleanData(store);
            limits.RH.resetCleanData(store);
            limits.DP.resetCleanData(store);

            // Register before unmount hook.
            onBeforeUnmount(() => {
                // limits.updateFormData({
                //     temp: { checked: false },
                //     rh: { checked: false },
                //     dp: { checked: false },
                // });
            });

            // ==== DEBUG ====
            /**
             * Computed debug frame.
             */
            const frame = computed(
                () => {
                    const deps = [
                        limits.T.dirtyData.value,
                        limits.RH.dirtyData.value,
                        limits.DP.dirtyData.value,
                    ];

                    // Prepare data.
                    const data = [
                        DebugObject.create(`Store`, {
                            ...limits.T.getStoreData(store),
                            ...limits.RH.getStoreData(store),
                            ...limits.DP.getStoreData(store),
                        }),
                        DebugObject.create(`T`, limits.T.getFormattedData()),
                        DebugObject.create(`RH`, limits.RH.getFormattedData()),
                        DebugObject.create(`DP`, limits.DP.getFormattedData()),
                    ];
                    // Return new frame instance.
                    return useDebugFrame({
                        isEnabled: true,
                        startHidden: true,
                        data,
                    });
                },
                {
                    // onTrack(e) {
                    //     debugger;
                    // },
                    // onTrigger(e) {
                    //     debugger;
                    // },
                }
            );

            // ==== EXPOSE ====
            return {
                isNARAEnabled,

                formClasses,
                frame,
                limits,
                T: limits.T.dirtyData,
                RH: limits.RH.dirtyData,
                DP: limits.DP.dirtyData,

                // MODAL
                viewNARAStandardsModal,
            };
        },
    });
</script>

<style scoped>
    /** See: https://vuejs.org/guide/built-ins/transition-group.html#enter-leave-transitions */
    .list-move, /* apply transition to moving elements */
    .list-enter-active,
    .list-leave-active {
        transition: all 0.5s ease;
    }

    .list-enter-from,
    .list-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }

    /* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
    .list-leave-active {
        position: absolute;
    }
</style>
