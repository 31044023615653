<template>
    <div
        class="w-full flex items-center justify-between p-3 space-x-6 bg-gray-100"
    >
        <div
            class="flex-1 truncate"
            :class="{ 'animate-pulse': isLoading }"
        >
            <p
                class="text-gray-800 text-base font-medium whitespace-normal overflow-visible sm:whitespace-nowrap sm:truncate"
                :title="title?.value"
            >
                <span class="font-bold">{{ title?.label }}: </span>
                <span :class="hasResource ? '' : 'text-gray-400'">{{
                    title?.value
                }}</span>
            </p>
            <p
                v-if="['Location'].includes(type) && isNARAEnabled"
                class="mt-1 text-gray-800 text-base truncate"
                :title="standard?.value"
            >
                <span class="font-bold">{{ standard?.label }}: </span>
                <span :class="hasStandard ? '' : 'text-gray-400'">{{
                    standard?.value
                }}</span>
            </p>
            <p
                class="mt-1 text-gray-800 text-base truncate"
                :title="dateRange?.value"
            >
                <span class="font-bold">{{ dateRange?.label }}: </span>
                <span :class="hasStatistics ? '' : 'text-gray-400'">{{
                    dateRange?.value
                }}</span>
            </p>
        </div>
    </div>
    <div>
        <div class="-mt-px flex divide-x divide-gray-200">
            <div
                v-if="isReady && hasStatistics"
                class="w-0 flex-1 flex flex-col md:flex-row whitespace-nowrap"
            >
                <div
                    class="flex-col relative w-full md:w-0 flex-1 inline-flex justify-start px-2 py-4 text-xs text-gray-700 border border-transparent rounded-bl-lg"
                    v-for="metric of OrderedMetricIDs"
                    :key="metric + '_column'"
                >
                    <span
                        class="font-bold mb-2 whitespace-nowrap text-xs lg:text-base xl:text-sm xl:pb-1 pb-1 lg:pb-0"
                    >
                        <!-- COLUMN HEADER -->
                        {{ MetricLabels[metric] }}
                    </span>
                    <span
                        class="text-left text-base"
                        v-for="id of OrderedValueStatisticIDs"
                        :key="id + metric + StatisticKeysByMetric[metric][id]"
                    >
                        <!-- ROW LABEL AND KEY -->
                        {{
                            formatValueStatisticLabel(
                                statistics,
                                StatisticKeysByMetric[metric][id]
                            )
                        }}
                    </span>
                    <span
                        class="w-full py-0.5 bg-gray-50 rounded-lg"
                        v-if="getLimitStatisticIDs(metric).length"
                    />
                    <span
                        class="text-left text-base"
                        v-for="id of getLimitStatisticIDs(metric)"
                        :key="id + metric + StatisticKeysByMetric[metric][id]"
                    >
                        <!-- ROW LABEL AND KEY -->
                        {{
                            formatLimitStatisticLabel(
                                statistics,
                                id,
                                StatisticKeysByMetric[metric][id]
                            )
                        }}
                    </span>
                </div>
            </div>
            <div
                v-else-if="isReady"
                class="p-2 my-2 flex flex-1 flex-col items-center justify-center whitespace-normal"
            >
                <p>There is no data present for this {{ resourceType }}.</p>
                <p>
                    Upload data in order to calculate statistics for this
                    {{ resourceType }}.
                </p>
            </div>
            <div
                v-else
                class="animate-pulse p-2 my-2 flex flex-1 flex-col items-center justify-center whitespace-normal"
            >
                <p>
                    <CogIcon
                        class="relative inline-block animate-spin h-5 w-5 -mt-0.5"
                    />
                    Loading {{ resourceType }} statistics...
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRefs, ref } from 'vue';

    // <!-- COMPONENTS -->
    import { CogIcon } from '@heroicons/vue/solid';

    // <!-- COMPOSABLES -->
    import { computedEager, createUnrefFn } from '@vueuse/core';
    import { useStore } from 'vuex';
    import { useNARAFeature } from '@/utils/features/';

    // <!-- UTILITIES -->
    import { formatDecimal } from '@/utils/formatters';
    import is from '@sindresorhus/is';
    import { Emoji } from '@/utils/emoji';
    import { Unit as Units } from '@/enums';

    // <!-- TYPES -->
    /** @typedef {import('vuex').Store<ECNBState>} Store */
    import { ECNBState } from '@/store/types/ECNBStore';
    import { LocationResource } from '@/models/v1/locations/Location';

    // <!-- CONSTANTS -->

    /** Record of resource labels. */
    const ResourceLabels = Object.freeze({
        Location: 'Location',
        WeatherStation: 'Weather Station',
    });

    /** Record of metric labels. */
    const MetricLabels = Object.freeze({
        T: 'Temperature',
        RH: 'Relative Humidity',
        DP: 'Dew Point',
    });

    /** Record of statistic labels. */
    const StatisticLabels = Object.freeze({
        MEAN: 'Mean',
        MED: 'Median',
        MIN: 'Min',
        MAX: 'Max',
        SD: 'Std. Dev.',
        BELOW: `< ${Emoji.infinity}`,
        IN: `[${Emoji.infinity}, ${Emoji.infinity}]`,
        ABOVE: `> ${Emoji.infinity}`,
    });

    /** Record of statistic keys. */
    const StatisticKeysByMetric = Object.freeze(
        /** @type {const} */ ({
            T: {
                MEAN: 's_temp_mean',
                MED: 's_t_median',
                MIN: 's_temp_min',
                MAX: 's_temp_max',
                SD: 's_temp_sd',
                ABOVE: 's_t_above',
                BELOW: 's_t_below',
                IN: 's_t_in',
            },
            RH: {
                MEAN: 's_rh_mean',
                MED: 's_rh_median',
                MIN: 's_rh_min',
                MAX: 's_rh_max',
                SD: 's_rh_sd',
                ABOVE: 's_rh_above',
                BELOW: 's_rh_below',
                IN: 's_rh_in',
            },
            DP: {
                MEAN: 's_dp_mean',
                MED: 's_dp_median',
                MIN: 's_dp_min',
                MAX: 's_dp_max',
                SD: 's_dp_sd',
                ABOVE: 's_dp_above',
                BELOW: 's_dp_below',
                IN: 's_dp_in',
            },
        })
    );

    /** @type {Readonly<Array<MetricID>>} Ordered array of metric ids. */
    const OrderedMetricIDs = ['T', 'RH', 'DP'];

    /** @type {Readonly<Array<StatisticID>>} Ordered array of statistic ids. */
    const OrderedStatisticIDs = /** @type {Array<StatisticID>} */ ([
        ...Object.keys(StatisticLabels),
    ]);

    /** @type {Readonly<Array<ValueStatisticID>>} Ordered array of value statistic ids. */
    const OrderedValueStatisticIDs = /** @type {Array<ValueStatisticID>} */ (
        OrderedStatisticIDs.filter((id) =>
            ['MEAN', 'MED', 'MIN', 'MAX', 'SD'].includes(id)
        )
    );

    // <!-- TYPES -->

    /** @typedef {Units[keyof typeof Units['_dictionary']]} Unit */
    /** @typedef {MetricLabels[MetricID]} Metric */
    /** @typedef {keyof typeof MetricLabels} MetricID */
    /** @typedef {StatisticLabels[StatisticID]} StatisticLabel */
    /** @typedef {keyof typeof StatisticLabels} StatisticID */
    /** @typedef {keyof Omit<StatisticLabels, 'ABOVE' | 'BELOW' | 'IN'>} ValueStatisticID */
    /** @typedef {keyof Pick<StatisticLabels, 'ABOVE' | 'BELOW' | 'IN'>} LimitStatisticID */
    /** @typedef {StatisticKeysByMetric['T'][StatisticID]} TStatisticKey */
    /** @typedef {StatisticKeysByMetric['RH'][StatisticID]} RHStatisticKey */
    /** @typedef {StatisticKeysByMetric['DP'][StatisticID]} DPStatisticKey */
    /** @typedef {StatisticKeysByMetric[MetricID][StatisticID]} MetricStatisticKey */
    /** @typedef {StatisticKeysByMetric[MetricID][ValueStatisticID]} ValueMetricStatisticKey */
    /** @typedef {StatisticKeysByMetric[MetricID][LimitStatisticID]} LimitMetricStatisticKey */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'StatisticsCard',
        components: {
            CogIcon,
        },
        props: {
            type: {
                type: /** @type {V.PropType<'Resource' | 'Location' | 'Weather Station'>} */ (
                    String
                ),
                default: () => 'Resource',
            },
            resource: {
                /** @type {V.PropType<LocationResource | import('@/models/weather/WeatherStation').WeatherStationResource>} */
                type: Object,
                default: () => null,
            },
            statistics: {
                /** @type {V.PropType<import('@/models/v1/locations/LocationStats').LocationStatsResource>} */
                type: Object,
                default: () => null,
            },
            loading: {
                /** @type {V.PropType<boolean>} */
                type: Boolean,
                default: () => false,
            },
        },
        setup(props, context) {
            // DESTRUCTURE reactive props.
            const { type, resource, statistics, loading } = toRefs(props);

            // DEFINE composable dependencies.

            const { isNARAEnabled, isNARADisabled } = useNARAFeature();

            /** @type {Store} */
            const store = useStore();

            /** @type {(label: string, value?: string) => Readonly<{ label: String, value: String }>} */
            const _makeLabel = (label, value) =>
                Object.freeze({
                    label,
                    value: value ?? 'N/A',
                });

            /** Create a maybe-reactive UI label. */
            const useLabel = createUnrefFn(_makeLabel);

            // DEFINE computed properties.

            /** The name of the resource, if available. */
            const title = computedEager(
                () => {
                    let label = 'Resource';
                    const pending = 'Loading...';
                    const missing = 'Unknown';
                    const defaultValue = !!isLoading.value ? pending : missing;
                    let value = defaultValue;
                    if (!!hasLocation.value) {
                        const _location = location.value;
                        label = ResourceLabels.Location;
                        value = _location.label ?? _location.name ?? null;
                    } else if (!!hasWeatherStation.value) {
                        const _weatherStation = weatherStation.value;
                        label = ResourceLabels.WeatherStation;
                        value =
                            _weatherStation.label ??
                            _weatherStation.name ??
                            null;
                    }
                    return useLabel(label, value ?? defaultValue);
                },
                { flush: 'post' }
            );

            /** The name of the location's assigned NARA Standard, if available. */
            const standard = computedEager(
                () => {
                    const label = 'NARA Standard';
                    const pending = 'Loading...';
                    const missing = 'No Standard Assigned';
                    const defaultValue = !!isLoading.value ? pending : missing;
                    let value = defaultValue;
                    if (!!hasStandard.value) {
                        const _standard =
                            location.value?.naraStandard?.standard ?? null;
                        value = is.nonEmptyString(_standard) ? _standard : null;
                    }
                    return useLabel(label, value ?? defaultValue);
                },
                { flush: 'post' }
            );

            /** The formatted date range string. */
            const dateRange = computedEager(
                () => {
                    const label = 'Date Range';
                    const pending = 'Loading...';
                    const missing = 'N/A';
                    const defaultValue = !!isLoading.value ? pending : missing;
                    let value = defaultValue;

                    // GET DATES.
                    const start = startDate.value;
                    const end = endDate.value;
                    if (is.nonEmptyString(start) && is.nonEmptyString(end)) {
                        value = `${start} - ${end}`;
                    }
                    return useLabel(label, value ?? defaultValue);
                },
                { flush: 'post' }
            );

            /** The resource type, used for text. */
            const resourceType = computedEager(
                () => {
                    let type = 'resource';
                    if (!!hasLocation.value) {
                        type = ResourceLabels.Location.toLowerCase();
                    } else if (!!hasWeatherStation.value) {
                        type = ResourceLabels.WeatherStation.toLowerCase();
                    }
                    return type;
                },
                { flush: 'post' }
            );

            /** The current selected account's temperature scale mode. */
            const currentTemperatureScale = computedEager(() =>
                store.state.accounts.account.temperatureScale === 'F'
                    ? 'F'
                    : 'C'
            );

            /** Resource object, casted as a location resource. */
            const location = computedEager(
                () => /** @type {LocationResource} */ (resource.value)
            );

            /** Resource object, casted as a weather station resource. */
            const weatherStation = computedEager(
                () =>
                    /** @type {import('@/models/weather/WeatherStation').WeatherStationResource} */ (
                        resource.value
                    )
            );

            /** The start date used to calculate the statistics.  */
            const startDate = computedEager(
                () => statistics.value?.s_start_date ?? null
            );

            /** The end date used to calculate the statistics.  */
            const endDate = computedEager(
                () => statistics.value?.s_end_date ?? null
            );

            /** The formatted units by metric ID. */
            const units = computedEager(() => {
                const _tScale = currentTemperatureScale.value;
                const _tKey = _tScale === 'F' ? 'Fahrenheit' : 'Celsius';
                const _tUnit = /** @type {'°F' | '°C'} */ (Units[_tKey]);
                const _percentUnit = /** @type {'%'} */ (Units['Percent']);
                return Object.freeze({
                    T: `T${_tUnit}`,
                    RH: `RH${_percentUnit}`,
                    DP: `DP${_tUnit}`,
                });
            });

            /** The formatted limits by metric ID. */
            const limits = computedEager(() => {
                const _tScale = currentTemperatureScale.value;
                const _tKey = _tScale === 'F' ? 'Fahrenheit' : 'Celsius';
                const _tUnit = /** @type {'°F' | '°C'} */ (Units[_tKey]);
                const _percentUnit = /** @type {'%'} */ (Units['Percent']);
                return Object.freeze(
                    /** @type {const} */ ({
                        T: {
                            BELOW: `< #${_tUnit}`,
                            IN: `[#${_tUnit}, #${_tUnit}]`,
                            ABOVE: `> #${_tUnit}`,
                        },
                        RH: {
                            BELOW: `< #${_percentUnit}`,
                            IN: `[#${_percentUnit}, #${_percentUnit}]`,
                            ABOVE: `> #${_percentUnit}`,
                        },
                        DP: {
                            BELOW: `< #${_tUnit}`,
                            IN: `[#${_tUnit}, #${_tUnit}]`,
                            ABOVE: `> #${_tUnit}`,
                        },
                    })
                );
            });

            /** The formatted statistic labels by statistic key. */
            const labels = computedEager(() => {
                const _metricLimits = limits.value;
                const _metricUnit = units.value;
                const _T = _metricUnit.T;
                const _RH = _metricUnit.RH;
                const _DP = _metricUnit.DP;
                return Object.freeze({
                    s_temp_mean: `${_T} ${StatisticLabels.MEAN}`,
                    s_rh_mean: `${_RH} ${StatisticLabels.MEAN}`,
                    s_dp_mean: `${_DP} ${StatisticLabels.MEAN}`,
                    s_temp_min: `${_T} ${StatisticLabels.MIN}`,
                    s_rh_min: `${_RH} ${StatisticLabels.MIN}`,
                    s_dp_min: `${_DP} ${StatisticLabels.MIN}`,
                    s_temp_max: `${_T} ${StatisticLabels.MAX}`,
                    s_rh_max: `${_RH} ${StatisticLabels.MAX}`,
                    s_dp_max: `${_DP} ${StatisticLabels.MAX}`,
                    s_t_median: `${_T} ${StatisticLabels.MED}`,
                    s_rh_median: `${_RH} ${StatisticLabels.MED}`,
                    s_dp_median: `${_DP} ${StatisticLabels.MED}`,
                    s_temp_sd: `${_T} ${StatisticLabels.SD}`,
                    s_rh_sd: `${_RH} ${StatisticLabels.SD}`,
                    s_dp_sd: `${_DP} ${StatisticLabels.SD}`,
                    s_t_below: `${_T} ${StatisticLabels.BELOW}`.replace(
                        StatisticLabels.BELOW,
                        _metricLimits.T.BELOW
                    ),
                    s_rh_below: `${_RH} ${StatisticLabels.BELOW}`.replace(
                        StatisticLabels.BELOW,
                        _metricLimits.RH.BELOW
                    ),
                    s_dp_below: `${_DP} ${StatisticLabels.BELOW}`.replace(
                        StatisticLabels.BELOW,
                        _metricLimits.DP.BELOW
                    ),
                    s_t_in: `${_T} ${StatisticLabels.IN}`.replace(
                        StatisticLabels.IN,
                        _metricLimits.T.IN
                    ),
                    s_rh_in: `${_RH} ${StatisticLabels.IN}`.replace(
                        StatisticLabels.IN,
                        _metricLimits.RH.IN
                    ),
                    s_dp_in: `${_DP} ${StatisticLabels.IN}`.replace(
                        StatisticLabels.IN,
                        _metricLimits.DP.IN
                    ),
                    s_t_above: `${_T} ${StatisticLabels.ABOVE}`.replace(
                        StatisticLabels.ABOVE,
                        _metricLimits.T.ABOVE
                    ),
                    s_rh_above: `${_RH} ${StatisticLabels.ABOVE}`.replace(
                        StatisticLabels.ABOVE,
                        _metricLimits.RH.ABOVE
                    ),
                    s_dp_above: `${_DP} ${StatisticLabels.ABOVE}`.replace(
                        StatisticLabels.ABOVE,
                        _metricLimits.DP.ABOVE
                    ),
                });
            });

            // DEFINE conditionals.

            /** Does the component have a valid record of location statistics? */
            const hasStatistics = computedEager(
                () =>
                    is.nonEmptyObject(statistics.value) &&
                    is.nonEmptyString(statistics.value?.s_start_date)
            );

            /** Does the component have a valid location prop? */
            const hasLocation = computedEager(
                () =>
                    is.nonEmptyObject(resource.value) &&
                    is.number(resource.value?.id) &&
                    is.nonEmptyStringAndNotWhitespace(resource.value?.name) &&
                    type.value === 'Location'
            );

            /** Does the component have a valid weather station prop? */
            const hasWeatherStation = computedEager(() => {
                return (
                    is.nonEmptyObject(resource.value) &&
                    is.nonEmptyStringAndNotWhitespace(resource.value?.name) &&
                    type.value === 'Weather Station'
                );
            });

            /** Does the component have a valid resource prop? */
            const hasResource = computedEager(
                () =>
                    !!hasLocation.value ||
                    !!hasWeatherStation.value ||
                    type.value === 'Resource'
            );

            /** Does the location have an assigned NARA Standard? */
            const hasStandard = computedEager(
                () =>
                    !!hasLocation.value &&
                    is.nonEmptyObject(location.value?.naraStandard) &&
                    is.nonEmptyString(location.value.naraStandard?.standard)
            );

            /** Is the card still loading? */
            const isLoading = computedEager(() => loading.value === true);

            /** Is the card ready to display information? */
            const isReady = computedEager(() => isLoading.value !== true);

            // DEFINE methods.

            /**
             * Get the statistic label specified by its metric (T/RH/DP) and its id (MEAN/MED/etc.).
             * @param {import('@/models/v1/locations/LocationStats').LocationStatsResource} record
             * @param {ValueMetricStatisticKey} key
             */
            const getValueStatisticLabel = (record, key) => {
                const label = labels.value[key];
                const pending = '??';
                const missing = 'None';
                const defaultValue = !!isLoading.value ? pending : missing;
                let value = defaultValue;
                if (!!hasStatistics.value) {
                    value = String(record[key]) ?? defaultValue;
                }
                return useLabel(label, value ?? defaultValue);
            };

            /**
             * Format the value in the record by its corresponding label (sharing the same key).
             * @param {import('@/models/v1/locations/LocationStats').LocationStatsResource} record
             * @param {ValueMetricStatisticKey} key
             */
            const formatValueStatisticLabel = (record, key) => {
                const { label, value } = getValueStatisticLabel(record, key);

                const roundedVal = formatDecimal({
                    value: Number(value),
                    options: {
                        maximumFractionDigits: [
                            's_rh_min',
                            's_rh_median',
                            's_rh_max',
                        ].includes(key)
                            ? 0
                            : 1,
                    },
                });

                return `${label}: ${roundedVal}`;
            };

            /**
             * Get the statistic label specified by its metric (T/RH/DP) and its id (MEAN/MED/etc.).
             * @param {import('@/models/v1/locations/LocationStats').LocationStatsResource} record
             * @param {LimitStatisticID} statistic
             * @param {LimitMetricStatisticKey} key
             */
            const getLimitStatisticLabel = (record, statistic, key) => {
                // GET NARA Standard LIMITS.
                let lower = NaN;
                let upper = NaN;
                if (isNARADisabled.value) {
                    const limits = key.includes('t')
                        ? store.state.analysis.filters.limits.temp
                        : key.includes('rh')
                        ? store.state.analysis.filters.limits.rh
                        : key.includes('dp')
                        ? store.state.analysis.filters.limits.dp
                        : {};

                    lower =
                        limits.lower !== -Infinity
                            ? limits.lower
                            : limits.upper;
                    upper =
                        limits.upper !== Infinity ? limits.upper : limits.lower;
                } else if (!!hasStandard.value) {
                    const _standard = location.value.naraStandard;
                    if (!!_standard) {
                        if (key.includes('t')) {
                            lower = Math.round(_standard.min_temp);
                            upper = Math.round(_standard.max_temp);
                        }
                        if (key.includes('rh')) {
                            lower = _standard.min_rh;
                            upper = _standard.max_rh;
                        }
                        if (key.includes('dp')) {
                            // TODO: Replace with DP limits in the standard?
                            lower = Math.round(_standard.min_temp);
                            upper = Math.round(_standard.max_temp);
                        }
                    }
                }

                // GET NARA Standard METRIC LABEL
                const _labels = /** @type {labels['value']} */ (labels.value);
                const _labelFormat = _labels[key];
                const defaultLower = 'N/A';
                const defaultUpper = 'N/A';
                let label = _labelFormat;
                switch (statistic) {
                    case 'BELOW':
                        label = label.replace(
                            '< #',
                            `< ${lower ?? defaultLower}`
                        );
                        break;
                    case 'IN':
                        label = label.replace(
                            '[#',
                            `[${lower ?? defaultLower}`
                        );
                        label = label.replace(
                            ', #',
                            `, ${upper ?? defaultUpper}`
                        );
                        break;
                    case 'ABOVE':
                        label = label.replace(
                            '> #',
                            `> ${upper ?? defaultUpper}`
                        );
                        break;
                    default:
                        break;
                }

                // GET NARA Standard METRIC VALUE
                const pending = '??';
                const missing = 'None';
                const defaultValue = !!isLoading.value ? pending : missing;
                let value = defaultValue;
                if (!!hasStatistics.value) {
                    value = String(record[key]) ?? defaultValue;
                }

                // CREATE label:
                return useLabel(label, value ?? defaultValue);
            };

            /**
             * Format the value in the record by its corresponding label (sharing the same key).
             * @param {import('@/models/v1/locations/LocationStats').LocationStatsResource} record
             * @param {LimitStatisticID} statistic
             * @param {LimitMetricStatisticKey} key
             */
            const formatLimitStatisticLabel = (record, statistic, key) => {
                const { label, value } = getLimitStatisticLabel(
                    record,
                    statistic,
                    key
                );
                return `${label}: ${value}${Units.Percent}`;
            };

            /**
             * Get visible Statistic IDs for the given metric.
             * @param {MetricID} metric
             */
            const getLimitStatisticIDs = (metric) => {
                if (isNARAEnabled.value) {
                    if (!!hasStandard.value) {
                        return OrderedStatisticIDs.filter((id) =>
                            ['BELOW', 'IN', 'ABOVE'].includes(id)
                        );
                    }

                    return [];
                }

                const limits =
                    metric === 'T'
                        ? store.state.analysis.filters.limits.temp
                        : metric === 'RH'
                        ? store.state.analysis.filters.limits.rh
                        : metric === 'DP'
                        ? store.state.analysis.filters.limits.dp
                        : {};

                const ids = [];
                if (limits.lower !== -Infinity || limits.upper !== Infinity) {
                    ids.push('BELOW');
                    if (
                        limits.lower !== -Infinity &&
                        limits.upper !== Infinity &&
                        limits.lower !== limits.upper
                    ) {
                        ids.push('IN');
                    }
                    ids.push('ABOVE');
                }

                return OrderedStatisticIDs.filter((id) => ids.includes(id));
            };

            // <!-- EXPOSE -->
            return {
                // CONSTANTS
                isNARAEnabled,
                ResourceLabels,
                MetricLabels,
                StatisticLabels,
                StatisticKeysByMetric,
                OrderedMetricIDs,
                OrderedValueStatisticIDs,
                // COMPUTED
                title,
                standard,
                dateRange,
                location,
                weatherStation,
                resourceType,
                // CONDITIONALS
                hasResource,
                hasLocation,
                hasWeatherStation,
                hasStatistics,
                hasStandard,
                isLoading,
                isReady,
                // FORMATTERS
                getValueStatisticLabel,
                getLimitStatisticLabel,
                formatValueStatisticLabel,
                formatLimitStatisticLabel,
                getLimitStatisticIDs,
            };
        },
    });
</script>
