// <!-- API -->
import { createEventHook, resolveUnref } from '@vueuse/core';

// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- UTILITIES -->
import is from '@sindresorhus/is';

// <!-- COMPOSABLES -->
import { useNARAFeature } from '@/utils/features';

// <!-- MODELS -->
import { CustomReportComponent } from '@/models/v1/reports';

// <!-- ENUMS -->
import { ReportType } from '@/enums';

// <!-- TYPES -->
/**
 * Individual parameters used in the report download request body.
 * @template {keyof typeof ReportType['_dictionary']} [T=keyof typeof ReportType['_dictionary']]
 * @typedef {{ report_type: T }} ReportTypeParam Report type.
 * @typedef {{ account_id: number }} AccountIdParam Account resource parameter.
 * @typedef {{ start_time: number, end_time: number }} ReportDateRangeParam ISO-8601 formatted datetime string representation specifying the date range to generate reports for. eg. `2022-12-31T00:00:00.000Z`.
 * @typedef {{ locations: string[] }} ReportLocationsParam Ordered collection of location and weather station resource ids to generate reports for.
 * @typedef {{ charts: Array<CustomReportComponent['id']> }} ReportChartsParam Ordered collection of custom report component chart ids.
 * @typedef {{ min_temperature?: number, max_temperature?: number, min_relative_humidity?: number, max_relative_humidity?: number, min_dewpoint?: number, max_dewpoint?: number }} ReportLimitsParam
 * @typedef {{ nara?: boolean }} ReportNARAParam NARA feature flag.
 * @typedef {Combine<ReportTypeParam<'overview'> & ReportDateRangeParam & ReportLocationsParam & Partial<ReportChartsParam>> & ReportLimitsParam & ReportNARAParam} OverviewReportRequestBody Request body for a custom report.
 * @typedef {Combine<ReportTypeParam<'performance'> & ReportDateRangeParam & ReportLocationsParam & Partial<ReportChartsParam>> & ReportLimitsParam & ReportNARAParam} PerformanceReportRequestBody Request body for a custom report.
 * @typedef {Combine<ReportTypeParam<'compare'> & ReportDateRangeParam & ReportLocationsParam & Partial<ReportChartsParam>> & ReportLimitsParam & ReportNARAParam} CompareMetricsReportRequestBody Request body for a custom report.
 * @typedef {Combine<ReportTypeParam<'nara'> & ReportDateRangeParam & ReportLocationsParam & Partial<ReportChartsParam>> & ReportLimitsParam & ReportNARAParam} NARAStandardsReportRequestBody Request body for a custom report.
 * @typedef {Combine<ReportTypeParam<'custom'> & ReportDateRangeParam & ReportLocationsParam & Required<ReportChartsParam>> & ReportLimitsParam & ReportNARAParam} CustomReportRequestBody Request body for a custom report.
 * @typedef {OverviewReportRequestBody | PerformanceReportRequestBody | CompareMetricsReportRequestBody | NARAStandardsReportRequestBody | CustomReportRequestBody} ReportRequestBody Request body for a custom report.
 */

/**
 * Ping response type.
 * @typedef {{ name: string }} GraphingServerNameField
 * @typedef {{ version: 'local' | 'staging' | 'production' }} GraphingServerVersionField
 * @typedef {{ environment: 'local' | 'staging' | 'production' }} GraphingServerEnvironmentField
 * @typedef {{ pong: string }} GraphingServerPongField ISO-8601 formatted datetime string.
 * @typedef {{ memMB: string }} GraphingServerMemoryField
 * @typedef {{ hostname: string }} GraphingServerHostNameField
 * @typedef {{ loadavg: Array<number> }} GraphingServerLoadTimeAverageField
 * @typedef {{ uptime: number }} GraphingServerUptimeField
 * @typedef {{ storageLocation: string }} GraphingServerStorageField
 * @typedef {Combine<GraphingServerNameField & GraphingServerVersionField & GraphingServerEnvironmentField & GraphingServerPongField & GraphingServerMemoryField & GraphingServerHostNameField & GraphingServerLoadTimeAverageField & GraphingServerUptimeField & GraphingServerStorageField>} GraphingServerPingData
 */

/**
 * Individual properties used in report download event options.
 * @typedef {{ reportType: keyof typeof ReportType['_dictionary'] }} ReportTypeProperty Report type.
 * @typedef {{ data: Blob }} ReportBlobProperty Report blob response.
 */

/**
 * Report download request event options.
 * @typedef {Combine<ReportTypeProperty & ReportBlobProperty>} DownloadReadyEventArgs
 */

/**
 * Report download request event hooks.
 * @typedef {import('@vueuse/core').EventHook<DownloadReadyEventArgs>} DownloadReadyEventHook Triggered after report request is received from the backend, but before the response is transformed into a downloadble file format.
 */

// <!-- REQUEST -->

/**
 * Utility class for creating a report download request.
 */
export class ReportDownloadRequest {
    // STATIC INTERNAL METHODS

    /**
     * Creates event hooks used by this controller.
     */
    static events() {
        return {
            /**
             * Triggered after a response is received or when the request fails.
             * @type {DownloadReadyEventHook}
             */
            ready: createEventHook(),
        };
    }

    /**
     * Get access to the URL routes.
     */
    static get url() {
        return {
            /**
             * Generate the route for the download request.
             * @param {Combine<ReportTypeParam & AccountIdParam>} options
             * @return {string}
             */
            download: ({ report_type, account_id }) => {
                return `accounts/${account_id}/reports/${report_type}`;
            },
            /**
             * Generate the route for the ping request.
             * @return {string}
             */
            ping: () => {
                return `reports/ping`;
            },
        };
    }

    // STATIC FACTORY METHODS

    /**
     * Construct a request instance, using possibly reactive state values.
     * @param {object} options
     * @param {Vue.Ref<globalThis.Account.Model> | globalThis.Account.Model} options.account Account sending request.
     * @param {Vue.Ref<keyof typeof ReportType['_dictionary']> | keyof typeof ReportType['_dictionary']} options.reportType Report type to generate.
     * @param {Vue.Ref<number[] | string[]> | number[] | string[]} [options.checkedLocations] Checked location ids. Can be empty.
     * @param {Vue.Ref<number[] | string[]> | number[] | string[]} [options.checkedWeatherStations] Checked weather station ids. Can be empty.
     * @param {Vue.Ref<IDate> | IDate} [options.startDate] Start date as `Date` instance or timestamp as milliseconds since Unix epoch.
     * @param {Vue.Ref<IDate> | IDate} [options.endDate] End date as `Date` instance or timestamp as milliseconds since Unix epoch.
     * @param {Vue.Ref<number[]> | number[]} [options.charts] Ordered list of custom report component ids.
     * @param {Vue.Ref<number?> | number?} [options.minTemperature] Minimum temperature limit.
     * @param {Vue.Ref<number?> | number?} [options.maxTemperature] Maximum temperature limit.
     * @param {Vue.Ref<number?> | number?} [options.minRelativeHumidity] Minimum relative humidity limit.
     * @param {Vue.Ref<number?> | number?} [options.maxRelativeHumidity] Maximum relative humidity limit.
     * @param {Vue.Ref<number?> | number?} [options.minDewpoint] Minimum dewpoint limit.
     * @param {Vue.Ref<number?> | number?} [options.maxDewpoint] Maximum dewpoint limit.
     * @return {ReportDownloadRequest}
     */
    static fromState(options) {
        // Initialize request body params.
        const account_id = resolveUnref(options.account).id;
        const report_type = resolveUnref(options.reportType);
        const locations = [];
        const charts = [];

        // Format the start date.
        const timeStart = resolveUnref(options.startDate).valueOf();
        const start_time = new Date(timeStart).getTime() ?? 0;

        // Format the end date.
        const timeEnd = resolveUnref(options.endDate).valueOf();
        const end_time = new Date(timeEnd).getTime() ?? Date.now();

        // Append the checked locations.
        const checkedLocations = resolveUnref(options.checkedLocations ?? []);
        if (is.nonEmptyArray(checkedLocations)) {
            locations.push(...checkedLocations.map(String));
        }
        // Append the checked weather stations.
        const checkedWeatherStations = resolveUnref(
            options.checkedWeatherStations ?? []
        );
        if (is.nonEmptyArray(checkedWeatherStations)) {
            locations.push(...checkedWeatherStations.map(String));
        }

        // Append the custom charts.
        const components = resolveUnref(options.charts ?? []);
        if (report_type === 'custom' && is.nonEmptyArray(components)) {
            charts.push(...components);
        }

        // Unref the limits.
        const min_temperature = resolveUnref(options.minTemperature);
        const max_temperature = resolveUnref(options.maxTemperature);
        const min_relative_humidity = resolveUnref(options.minRelativeHumidity);
        const max_relative_humidity = resolveUnref(options.maxRelativeHumidity);
        const min_dewpoint = resolveUnref(options.minDewpoint);
        const max_dewpoint = resolveUnref(options.maxDewpoint);

        // Instantiate the request.
        const request = new ReportDownloadRequest({
            account_id,
            report_type,
            locations,
            start_time,
            end_time,
            charts,
            min_temperature,
            max_temperature,
            min_relative_humidity,
            max_relative_humidity,
            min_dewpoint,
            max_dewpoint,
        });

        // Expose the created request.
        return request;
    }

    // CONSTRUCTOR

    /**
     * Construct the request instance parameters.
     * @param {Combine<ReportRequestBody & AccountIdParam>} options
     */
    constructor(options) {
        this.events = ReportDownloadRequest.events();
        this.account_id = options.account_id;
        this.report_type = options.report_type;
        this.locations = options.locations;
        this.start_time = options.start_time;
        this.end_time = options.end_time;
        this.charts = options.charts ?? [];
        this.min_temperature = options.min_temperature;
        this.max_temperature = options.max_temperature;
        this.min_relative_humidity = options.min_relative_humidity;
        this.max_relative_humidity = options.max_relative_humidity;
        this.min_dewpoint = options.min_dewpoint;
        this.max_dewpoint = options.max_dewpoint;
        this.is_nara_enabled = useNARAFeature().isNARAEnabled;
    }

    // INTERNAL METHODS

    /**
     * Register the event handlers that are minimally required.
     */
    register() {
        this.lifecycle.onReady((options) => {
            console.debug(
                `Successfully downloaded blob for ${options.reportType} report.`
            );
        });
    }

    // PROPERTIES

    /**
     * Get lifecycle event listeners.
     */
    get lifecycle() {
        return {
            onReady: this.events.ready.on,
        };
    }

    /**
     * Get lifecycle event triggers.
     */
    get trigger() {
        return {
            ready: this.events.ready.trigger,
        };
    }

    /**
     * Get readonly reference to only the request body parameters.
     * @return {Readonly<ReportRequestBody>}
     */
    get body() {
        return {
            report_type: this.report_type,
            locations: this.locations,
            charts: this.charts,
            start_time: Math.trunc(this.start_time / 1000),
            end_time: Math.trunc(this.end_time / 1000),
            min_temperature: this.min_temperature,
            max_temperature: this.max_temperature,
            min_relative_humidity: this.min_relative_humidity,
            max_relative_humidity: this.max_relative_humidity,
            min_dewpoint: this.min_dewpoint,
            max_dewpoint: this.max_dewpoint,
            nara: this.is_nara_enabled.value,
        };
    }

    // SERVICE METHODS

    /**
     * Assert request configuration is valid. If nothing is provided, uses instance parameters.
     * @param {ReportRequestBody} [requestBody]
     * @return {void}
     * @throws {Error}
     */
    validate(requestBody = this.body) {
        const errors = [];

        // Required: report_type
        if (!is.nonEmptyStringAndNotWhitespace(requestBody.report_type)) {
            errors.push('No report type specified.');
        }

        // Required: start_date
        if (!is.number(requestBody.start_time)) {
            errors.push('No start time specified.');
        }

        // Required: end_date
        if (!is.number(requestBody.end_time)) {
            errors.push('No end time specified.');
        }

        // Required: locations
        if (!is.nonEmptyArray(requestBody.locations)) {
            errors.push('No locations currently selected.');
        }

        // Required conditionally: charts
        if (requestBody.report_type === 'custom') {
            if (!is.nonEmptyArray(requestBody.charts)) {
                errors.push('No charts currently selected.');
            }
        }

        // If one or more errors, fail the test.
        if (is.emptyArray(errors)) {
            return;
        }

        // Throw with error message.
        const message = errors.join('\n');
        throw new Error(message);
    }

    /**
     * Transform the response into a blob.
     * @param {import('axios').AxiosResponse<BlobPart, import('axios').AxiosRequestConfig<ReportRequestBody>>} response
     * @return {Blob}
     */
    transform(response) {
        // We are expecting a PDF.
        return new Blob([response.data], { type: 'application/pdf' });
    }

    /**
     * Send a health check to the graphing server.
     * @return {Promise<GraphingServerPingData>}
     */
    static async ping() {
        /** @type {string} */
        const requestUrl = ReportDownloadRequest.url.ping();

        /** @type {import('axios').AxiosRequestConfig<any>} */
        const requestConfig = { responseType: 'json' };

        /** @type {import('axios').AxiosResponse<GraphingServerPingData, typeof requestConfig>} */
        const response = await axios().get(requestUrl, requestConfig);

        /** The response data containing the healthcheck fields. */
        const data = response.data;
        return data;
    }

    /**
     * Send the response.
     * @return {Promise<DownloadReadyEventArgs>}
     */
    async send() {
        // Assert configuration is valid.
        this.validate();

        /** @type {string} */
        const requestUrl = ReportDownloadRequest.url.download(this);

        /** @type {ReportRequestBody} */
        const requestBody = this.body;

        /** @type {import('axios').AxiosRequestConfig<typeof requestBody>} */
        const requestConfig = { responseType: 'blob' };

        /** @type {import('axios').AxiosResponse<BlobPart, typeof requestConfig>} */
        const response = await axios().post(
            requestUrl,
            requestBody,
            requestConfig
        );

        // Transform the response into a PDF blob.
        const blob = this.transform(response);

        /** @type {DownloadReadyEventArgs} */
        const event = {
            reportType: requestBody.report_type,
            data: blob,
        };

        // Note: This interface allows users to either
        //       listen to the request's `onReady` event
        //       or await the promise directly.

        // Trigger the ready event.
        this.trigger.ready(event);

        // Return the promise result.
        return event;
    }
}

// <!-- DEFAULT -->
export default ReportDownloadRequest;
